"use client";

// External imports
import React from "react";
import dynamic from "next/dynamic";
import clsx from "clsx";
import Image from "next/image";
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'
import type { Swiper as SwiperType } from 'swiper';
import { Zoom, FreeMode, Navigation, Thumbs } from 'swiper/modules';

import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// Internal imports
import { Grid, Column } from "@/components/layout/Grid";
import { getDefaultACFSettings } from "@/helpers/getDefaultACFSettings";
import { calculateImageSize } from "@/helpers/calculateImageSize";
import { LayoutIntro } from "@/components/LayoutIntro";
import cardStyles from "@/styles/Cards.module.scss";

// Type Imports
import type { IImages } from "@/types/ACFLayouts/Images";
import parse from "html-react-parser";
import { options } from "@/helpers/parseOptions";

// Lazy Load Imports
const ImageLightbox = dynamic(() =>
  import("@/components/ui/ImageLightbox").then((mod) => mod.ImageLightbox),
);
const HiddenDescription = dynamic(() =>
  import("@/components/ui/HiddenDescription").then(
    (mod) => mod.HiddenDescription,
  ),
);
const Caption = dynamic(() =>
  import("@/components/ui/Caption").then((mod) => mod.Caption),
);

type Props = {
  data: IImages;
  parentWidth?: number;
  nested?: boolean;
};

export const Images = ({ data, parentWidth, nested = false }: Props) => {
  const [currentIndex, setCurrentIndex] = React.useState(-1);
  const [isAccordionOpen, setIsAccordionOpen] = React.useState(false);
  const [thumbsSwiper, setThumbsSwiper] = React.useState<SwiperType|null>(null);
  const mainRef = React.useRef<SwiperRef|null>(null);

  const { hide, paddings, margins, classes, id, backgroundColor, bgImgStyles } =
    getDefaultACFSettings(data);

  // Group all main layout classes here
  const combinedClasses = clsx(
    "relative",
    "acf-layout",
    "images-block",
    paddings,
    margins,
    classes,
  );

  const sizes = calculateImageSize(+data.number_of_columns, parentWidth);

  React.useEffect(() => {
    const handleAccordionOpened = () => {
      setIsAccordionOpen((prev) => !prev);
    };

    window.addEventListener("accordionOpened", handleAccordionOpened);

    return () => {
      window.removeEventListener("accordionOpened", handleAccordionOpened);
    };
  }, []);

  if (hide || !data?.images || data?.images[0]?.image?.url === "") {
    return <></>;
  }

  return (
    <>
      <div
        id={id}
        className={`${combinedClasses}`}
        style={{ ...bgImgStyles, backgroundColor }}
      >
        <div className={`${!nested ? data.wrapper_width : ""}`}>
          {/*  Grid Cards - No Slider  */}
          {!data.display_in_a_slider && (
            <>
              {data.images.length > 0 && data?.images[0].image?.url && (
                <>
                  {/* Intro */}
                  {data.intro && <LayoutIntro intro={data.intro} />}

                  {/* Main Section */}
                  <Grid
                    classes={`${nested ? "md:gap-5" : ""}`}
                    columns={+data.number_of_columns}
                  >
                    {/* Columns */}
                    {data.images.map((image, index) => {
                      if (image?.image.url !== "") {
                        return (
                          <Column key={image.image.ID}>
                            <article
                              className={`${cardStyles.card} ${cardStyles["v-card"]}`}
                            >
                              {data.lightbox ? (
                                <button
                                  className={`group text-left image-lightbox relative 
                                  ${cardStyles.media} ${cardStyles[data.image_ratio]}`}
                                  onClick={() => setCurrentIndex(index)}
                                >
                                  <Image
                                    src={`${process.env.NEXT_PUBLIC_API_URL}${image?.image?.url}`}
                                    alt={image?.image?.alt}
                                    fill
                                    sizes={sizes}
                                    className={`${data.image_fit} inset-0 absolute object-center`}
                                  />

                                  {data.images.length > 1 &&
                                    image.image.description && (
                                      <HiddenDescription>
                                        <p>
                                          {parse(
                                            image.image.description,
                                            options,
                                          )}
                                        </p>
                                      </HiddenDescription>
                                    )}
                                </button>
                              ) : (
                                <div
                                  className={`group relative ${cardStyles.media} ${cardStyles[data.image_ratio]}`}
                                >
                                  <Image
                                    src={`${process.env.NEXT_PUBLIC_API_URL}${image?.image?.url}`}
                                    alt={image?.image?.alt}
                                    fill
                                    sizes={sizes}
                                    className={`${data.image_fit} inset-0 absolute object-center`}
                                  />

                                  {data.images.length > 1 &&
                                    image.image.description && (
                                      <HiddenDescription>
                                        <p>
                                          {parse(
                                            image.image.description,
                                            options,
                                          )}
                                        </p>
                                      </HiddenDescription>
                                    )}
                                </div>
                              )}
                            </article>

                            {data.images.length === 1 &&
                              image.image.caption && (
                                <Caption>
                                  <p>{parse(image.image.caption, options)}</p>
                                </Caption>
                              )}
                          </Column>
                        );
                      }
                    })}
                  </Grid>
                  {data.images.length > 1 && data.gallery_caption && (
                    <Caption>
                      <p>{parse(data.gallery_caption, options)}</p>
                    </Caption>
                  )}

                  {/* Lightbox  */}
                  {data.lightbox && (
                    <ImageLightbox
                      images={data.images}
                      currentIndex={currentIndex}
                      setCurrentIndex={setCurrentIndex}
                    />
                  )}
                </>
              )}
            </>
          )}

          {/* Slider of Posts */}
          {data.display_in_a_slider && (
            <>
              {data.images.length > 0 && data.type_of_slider === "basic" && (
                <>
                  {/* Intro */}
                  {data.intro && <LayoutIntro intro={data.intro} />}

                  <Swiper
                    slidesPerView={+data.number_of_columns}
                    spaceBetween={20}
                    modules={[Zoom, Navigation]}
                    zoom={{maxRatio: 4}}
                    navigation={{ nextEl: '.swiper-next', prevEl: '.swiper-prev', enabled: true }}
                  >
                    <div className="swiper-arrows flex z-[5] gap-2.5 !mt-5">
                      <div className="swiper-arrow swiper-prev"></div>
                      <div className="swiper-arrow swiper-next"></div>
                    </div>
                    {data.images.map((image) => (
                      <SwiperSlide key={image.image.ID}>
                        <article
                          className={`${cardStyles.card} ${cardStyles['v-card']} swiper-zoom-container`}
                        >
                          <div
                            className={`${cardStyles.media} ${
                              cardStyles[data.image_ratio]
                            }`}
                          >
                            {image?.image && (
                              <Image
                                src={`${process.env.NEXT_PUBLIC_API_URL}${image?.image?.url}`}
                                alt={image?.image?.alt}
                                fill
                                sizes={sizes}
                                className={`${data.image_fit} inset-0 absolute object-center`}
                              />
                            )}
                          </div>
                        </article>

                        {image.image.caption && (
                          <Caption classes={'copyright'}>
                            <p>{parse(image.image.caption, options)}</p>
                          </Caption>
                        )}
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </>
              )}


              {/*  Thumbnail Slider   */}
              {data.images.length > 0 &&
                data.type_of_slider === 'thumbnail' && (
                  <>
                    <Swiper
                      ref={mainRef}
                      spaceBetween={20}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[Zoom, FreeMode, Thumbs]}
                      zoom={{maxRatio: 4}}
                      className="mySwiper2 mb-5"
                      updateOnWindowResize={true}
                      onInit={(swiper) => setTimeout(() => swiper.update(), 1000)}
                    >
                      {data.images.map((image, index) => (
                        <SwiperSlide
                          key={image.image.ID}
                          className="relative min-h-[200px] aspect-video full w-full swiper-zoom-container"
                        >
                          <Image
                            src={`${process.env.NEXT_PUBLIC_API_URL}${image?.image?.url}`}
                            fill
                            alt={`Slide ${index + 1}`}
                            className={'object-cover object-center'}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>

                    <Swiper
                      onSwiper={setThumbsSwiper}
                      spaceBetween={20}
                      slidesPerView={4}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper"
                      navigation={{ nextEl: '.swiper-next', prevEl: '.swiper-prev', enabled: true }}
                      onInit={(swiper) => setTimeout(() => swiper.update(), 300)}
                    >
                      <div className="swiper-arrows flex z-[5] gap-2.5 !mt-5">
                        <div onClick={() => mainRef?.current?.swiper.slidePrev()} className="swiper-arrow swiper-prev"></div>
                        <div onClick={() => mainRef?.current?.swiper.slideNext()} className="swiper-arrow swiper-next"></div>
                      </div>
                      {data.images.map((image, index) => (
                        <SwiperSlide
                          key={image.image.ID}
                          className="relative cursor-pointer min-h-[100px] aspect-square"
                        >
                          <Image
                            src={`${process.env.NEXT_PUBLIC_API_URL}${image?.image?.url}`}
                            fill
                            alt={`Slide ${index + 1}`}
                            className={"object-cover object-center"}
                          />

                          {image.image.description && (
                            <Caption
                              classes={
                                "absolute bottom-0 bg-pn-black-base text-white"
                              }
                            >
                              <p>{parse(image.image.description, options)}</p>
                            </Caption>
                          )}
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </>
                )}

              {data.images.length === 0 && <></>}
            </>
          )}
        </div>
      </div>
    </>
  );
};
